export const colors = {
	black: '#000000',
	blackTwo: '#333333',
	twilightBlue: '#0f2f7f',
	white: '#ffffff',
	paleGrey: '#eceff5',
	darkishBlue: '#002a96',
	midnight: '#02050c',
	cobaltBlue: '#0031b0',
	brownGreyTwo: '#adadad',
	shamrockGreen: '#00c16c',
	marigold: '#ffc500',
	orange: '#e67f0d',
	lipstick: '#cb1723',
	veryLightPink: '#d1d1d1',
	coolBlue: '#4472ca',
	nightBlue: '#04102e',
	lightPeriwinkle: '#dcdde2',
	veryLightPinkTwo: '#c1c1c1',
	darkIndigo: '#0a2058',
	dullRed: '#cc2e2e',
	brownGreyThree: '#9a9a9a',
	casper: '#b7c0d8',
	botticelli: '#d8deea',
	veryLightPinkThree: '#c5c5c5',
	veryLightPinkFour: '#e2e2e2',
	greyBlue: '#667bae',
}

const fonts = {
	sansSerif: 'Regular, Arial, Helvetica Neue, Helvetica, sans-serif',
	serif: 'Lora, Georgia, Times, Times New Roman, serif',
}

const baseTheme = {
	colors,
	fonts: {
		primary: fonts.sansSerif,
		secondary: fonts.serif,
	},
}

export const lightTheme = {
	...baseTheme,
	isDark: false,
	color: {
		primary: colors.twilightBlue,
		secondary: colors.coolBlue,
		category: colors.darkishBlue,
	},
	text: {
		primary: colors.black,
		secondary: colors.twilightBlue,
		light: colors.blackTwo,
	},
	background: colors.white,
	button: {
		secondaryColor: colors.white,
		secondaryBg: colors.twilightBlue,
		secondaryBgHover: colors.darkIndigo,
		secondaryBgDisabled: colors.greyBlue,
	},
}

export const darkTheme: typeof lightTheme = {
	...baseTheme,
	isDark: true,
	color: {
		primary: colors.white,
		secondary: colors.brownGreyTwo,
		category: colors.white,
	},
	text: {
		primary: colors.white,
		secondary: colors.brownGreyTwo,
		light: colors.white,
	},
	background: colors.midnight,
	button: {
		secondaryColor: colors.black,
		secondaryBg: colors.white,
		secondaryBgHover: colors.brownGreyTwo,
		secondaryBgDisabled: colors.greyBlue,
	},
}
