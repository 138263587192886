'use client'

import React from 'react'
import { usePathname } from 'next/navigation'
import { GtmNoScript } from 'services/google-tag-manager'

export const BodyScripts = () => {
	const pathname = usePathname()

	const isTrafficMobile = pathname?.startsWith('/traffic-mobile') ?? false

	return !isTrafficMobile && <GtmNoScript />
}
