import { css } from 'styled-components'
import {
	mediaBreakpointOnlySm,
	mediaBreakpointOnlyMd,
	mediaBreakpointDownMd,
	mediaBreakpointDownLg,
} from 'theme/breakpoints'

export const helpers = css`
	.vhidden {
		visibility: hidden;
	}

	.nowrap {
		flex-wrap: nowrap;
	}

	.pt-12 {
		padding-top: 12px;
	}

	.mt-3 {
		/**
		 * !important is needed to overwrite
		 * the bootstrap class also named 'mt-3'
		 */
		margin-top: 3px !important;
	}

	.mt-24 {
		margin-top: 24px;
	}

	.mt-59 {
		margin-top: 59px;
	}

	.mt-64 {
		margin-top: 64px;
	}

	.mb-0 {
		margin-bottom: 0;
	}

	.mb-15 {
		margin-bottom: 15px;
	}

	.mb-17 {
		margin-bottom: 17px;
	}

	.mb-22 {
		margin-bottom: 22px;
	}

	.mb-23 {
		margin-bottom: 23px;
	}

	.mb-24 {
		margin-bottom: 24px;
	}

	.mb-26 {
		margin-bottom: 26px;
	}

	.mb-30 {
		margin-bottom: 30px;
	}

	.mb-32 {
		margin-bottom: 32px;
	}

	.mb-33 {
		margin-bottom: 33px;
	}

	.mb-35 {
		margin-bottom: 35px;
	}

	.mb-40 {
		margin-bottom: 40px;
	}

	.mb-44 {
		margin-bottom: 44px;
	}

	.mt-xs-6 {
		${mediaBreakpointDownLg} {
			margin-top: 6px;
		}
	}

	.mt-xs-12 {
		${mediaBreakpointDownLg} {
			margin-top: 12px;
		}
	}

	.mt-xs-20 {
		${mediaBreakpointDownLg} {
			margin-top: 20px;
		}
	}

	.mt-xs-23 {
		${mediaBreakpointDownLg} {
			margin-top: 23px;
		}
	}

	.mb-xs-6 {
		${mediaBreakpointDownLg} {
			margin-bottom: 6px;
		}
	}

	.mb-xs-8 {
		${mediaBreakpointDownLg} {
			margin-bottom: 8px;
		}
	}

	.mb-xs-12 {
		${mediaBreakpointDownLg} {
			margin-bottom: 12px;
		}
	}

	.mb-xs-16 {
		${mediaBreakpointDownLg} {
			margin-bottom: 16px;
		}
	}

	.mb-xs-17 {
		${mediaBreakpointDownLg} {
			margin-bottom: 17px;
		}
	}

	.mb-xs-18 {
		${mediaBreakpointDownLg} {
			margin-bottom: 18px;
		}
	}

	.mb-xs-21 {
		${mediaBreakpointDownLg} {
			margin-bottom: 21px;
		}
	}

	.mb-xs-24 {
		${mediaBreakpointDownLg} {
			margin-bottom: 24px;
		}
	}

	.mb-xs-31 {
		${mediaBreakpointDownLg} {
			margin-bottom: 31px;
		}
	}

	.mb-xs-32 {
		${mediaBreakpointDownLg} {
			margin-bottom: 32px;
		}
	}

	.mt-down-md-14 {
		${mediaBreakpointDownMd} {
			margin-top: 14px;
		}
	}

	.mt-only-sm-16 {
		${mediaBreakpointOnlySm} {
			margin-top: 16px;
		}
	}

	.mt-only-md-16 {
		${mediaBreakpointOnlyMd} {
			margin-top: 16px;
		}
	}

	.ml-12 {
		margin-left: 12px;
	}

	.no-scroll {
		width: 100vw;
		height: 100vh;
		overflow: hidden;
	}
`
