import React from 'react'

export const CookieproScripts = () => {
	let domainScriptId = process.env.NEXT_PUBLIC_COOKIE_PRO_KEY

	const COMMIT_REF = process.env.GITHUB_COMMIT_REF || process.env.VERCEL_GITHUB_COMMIT_REF

	if (process.env.NODE_ENV === 'development' || COMMIT_REF !== 'master') {
		domainScriptId += '-test'
	}

	return (
		<>
			<script
				defer
				src="https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js"
				data-document-language="true"
				data-domain-script={domainScriptId}></script>
			<script
				dangerouslySetInnerHTML={{
					__html: `function OptanonWrapper() {
						window['dataLayer'].push({
							event: 'OneTrustLoaded',
						})
					};`,
				}}></script>
		</>
	)
}
