'use client'

import React from 'react'
import { usePathname } from 'next/navigation'
import { GtmScript } from 'services/google-tag-manager'
import { CookieproScripts } from 'services/cookiepro'
import { ReadSpeakerScripts } from 'services/read-speaker'

export const HeadScripts = () => {
	const pathname = usePathname()

	const isTrafficMobile = pathname?.startsWith('/traffic-mobile') ?? false

	return (
		!isTrafficMobile && (
			<>
				<GtmScript />
				<CookieproScripts />
				<ReadSpeakerScripts />
			</>
		)
	)
}
