import React from 'react'

const gtmKey = process.env.NEXT_PUBLIC_GTM_KEY

export const GtmScript = () => {
	return (
		<script
			dangerouslySetInnerHTML={{
				__html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
				new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
				j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
				'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer', '${gtmKey}');`,
			}}></script>
	)
}

export const GtmNoScript = () => {
	return (
		<noscript>
			<iframe
				title="gtmNoScript"
				src={`https://www.googletagmanager.com/ns.html?id=${gtmKey}`}
				height="0"
				width="0"
				style={{ display: 'none', visibility: 'hidden' }}></iframe>
		</noscript>
	)
}
