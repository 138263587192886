'use client'

import React, { useState, useCallback, useEffect } from 'react'
import { useReadSpeakerContext } from 'providers/read-speaker-provider/read-speaker-provider'

export const ReadSpeakerScripts = () => {
	const [isLoaded, setIsLoading] = useState(false)
	const { setIsReadSpeakerLoaded } = useReadSpeakerContext()

	const onReadSpeakerLoad = useCallback(() => {
		setIsLoading(false)
	}, [setIsLoading])

	useEffect(() => {
		setIsReadSpeakerLoaded(isLoaded)
	}, [isLoaded, setIsReadSpeakerLoaded])

	return (
		<>
			<script
				dangerouslySetInnerHTML={{
					__html: `window.rsConf = {
						params: 'https://cdn1.readspeaker.com/script/9870/webReader/webReader.js?pids=wr',
						general: { usePost: true },
					}`,
				}}></script>
			<script
				src="https://cdn1.readspeaker.com/script/9870/webReader/webReader.js?pids=wr"
				onLoad={onReadSpeakerLoad}
				async></script>
		</>
	)
}
